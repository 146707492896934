import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

type State = {
  user: string;
  proMode: boolean;
};

type Actions = {
  addUser: (value: string) => void;
  deleteUser: () => void;
  switchProMode: (mode: boolean) => void;
};

const initialState: State = {
  user: '',
  proMode: false
};

export const useUserStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        addUser: (value) => set({ user: value }),
        deleteUser: () => set(initialState),
        switchProMode: (mode) => set({ proMode: mode })
      }),
      { name: 'user' }
    ),
    { name: 'userStore' }
  )
);
