import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface FilterState {
  search: string;
  payments: string;
  currency: string;
  dateFrom: string;
  dateTo: string;
  setSearch: (value: string) => void;
  clearFilters: () => void;
}

export const useFilterStore = create<FilterState>()(
  devtools(
    (set) => ({
      search: '',
      payments: '',
      currency: '',
      dateFrom: '',
      dateTo: '',
      setSearch: (value) => set({ search: value }),
      clearFilters: () =>
        set({
          search: '',
          payments: '',
          currency: '',
          dateFrom: '',
          dateTo: ''
        })
    }),
    { name: 'filterStore' }
  )
);
