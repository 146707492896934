import React from 'react';
import cls from './CustomLink.module.scss';
import { Link } from 'react-router-dom';

interface CustomLinkProps {
  children: React.ReactNode;
  href: string;
}

export const CustomLink = ({ children, href }: CustomLinkProps) => {
  return (
    <Link to={href} className={cls.customLink}>
      {children}
    </Link>
  );
};
