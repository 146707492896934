import React from 'react';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import cls from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <p>© 2024 PAYUOT.KG</p>
        </div>
      </WidthLimiterPadding>
    </footer>
  );
};
