import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface SignUpState {
  phoneNumber: string;
  addPhone: (value: string) => void;
}

export const useSignUpStore = create<SignUpState>()(
  devtools(
    (set) => ({
      phoneNumber: '',
      addPhone: (value) => set({ phoneNumber: value })
    }),
    { name: 'signUpStore' }
  )
);
