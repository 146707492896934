export const Paths = {
  APP: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_UP_STEP_2: '/sign-up/reg-sms',
  SIGN_UP_STEP_3: '/sign-up/reg-password',
  HISTORY: 'history',
  PAYMENT_DETAILS: 'history/:id',
  API: 'api',
  REGISTRY: 'registry',
  PAN: 'pan',
  SETTINGS: '/settings',
  PINCODE: 'pin-code',
  DELETE_ACC: 'delete-account',
  DELETED: '/deleted',
  NOT_FOUND: '*'
} as const;
