import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { normalizePhoneNumber } from '../../../app/helpers/normalizePhoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ReactCountryFlag from 'react-country-flag';

interface Props {
  phone: string;
  setPhone: (value: string) => void;
  setError: (value: boolean) => void;
  error?: boolean;
}

export const InputPhoneNum = (props: Props) => {
  const { phone, setPhone, setError, error } = props;
  const [country, setCountry] = useState('');
  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 2) {
      setPhone('+');
    } else {
      setPhone(normalizePhoneNumber(e.target.value));
    }
  };

  const onBlurPhone = () => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.isValid() ? setError(false) : setError(true);
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.country ? setCountry(phoneNumber?.country) : setCountry('');
  }, [phone]);

  return (
    <FormControl variant="outlined" style={{ gap: '8px' }}>
      <label htmlFor="phone">Введите свой номер телефона</label>
      <TextField
        required
        id="phone"
        name="phone"
        type="tel"
        placeholder="+"
        value={phone}
        onChange={onChangePhone}
        onBlur={onBlurPhone}
        error={error}
        helperText={error ? 'Некорректный номер' : ''}
        InputProps={{
          startAdornment: country && (
            <InputAdornment position="end">
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{ width: '20px' }}
              />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};
