import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './paths';
import { requireProLoader } from '../../helpers/requireProLoader';
import { App } from '../../App';
import {
  DeletedPage,
  ApiPage,
  ReestrPage,
  SignInPage,
  DeleteAccPage,
  SignUpPage,
  SettingsPage,
  PanPage,
  MainPage,
  MainOverview,
  PinCodePage,
  HistoryPage,
  PayDetailsPage,
  Step1,
  Step2,
  Step3,
  ErrorPage
} from '../../../pages';

export const router = createBrowserRouter([
  {
    path: Paths.APP,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: Paths.APP,
            element: <MainPage />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: Paths.APP,
                element: <MainOverview />
              },
              {
                path: Paths.HISTORY,
                element: <HistoryPage />
              },
              {
                path: Paths.PAYMENT_DETAILS,
                element: <PayDetailsPage />
              },
              {
                path: Paths.API,
                element: <ApiPage />,
                loader: requireProLoader
              },
              {
                path: Paths.REGISTRY,
                element: <ReestrPage />,
                loader: requireProLoader
              },
              {
                path: Paths.PAN,
                element: <PanPage />
              },
              {
                path: Paths.SETTINGS,
                element: <SettingsPage />,
                children: [
                  {
                    path: Paths.PINCODE,
                    element: <PinCodePage />
                  },
                  {
                    path: Paths.DELETE_ACC,
                    element: <DeleteAccPage />
                  }
                ]
              }
            ]
          },
          {
            path: Paths.SIGN_IN,
            element: <SignInPage />
          },
          {
            path: Paths.SIGN_UP,
            element: <SignUpPage />,
            children: [
              {
                path: Paths.SIGN_UP,
                element: <Step1 />
              },
              {
                path: Paths.SIGN_UP_STEP_2,
                element: <Step2 />
              },
              {
                path: Paths.SIGN_UP_STEP_3,
                element: <Step3 />
              }
            ]
          },
          {
            path: Paths.DELETED,
            element: <DeletedPage />
          }
        ]
      }
    ]
  }
]);
