import { ReactNode } from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter'
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#FF4000',
      dark: '#EB3B00',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#000',
      dark: 'rgba(0,0,0,0.7)',
      contrastText: '#FFF'
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          height: '44px',
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#FF5C33'
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            border: '1px solid rgba(255, 92, 51, 1)',
            boxShadow: '0px 0px 0px 3px rgba(255, 92, 51, 0.3)'
          }
        },
        notchedOutline: {
          borderColor: '#E8E8E8'
        },
        input: {
          padding: '10px 14px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {}
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        root: (state) => ({
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none',
          padding: '12px 20px',
          lineHeight: '20px',
          borderRadius: '8px',
          '&:hover': {}
        })
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        displayEmpty: true
      },
      styleOverrides: {
        root: {
          background: '#FFF',
          borderRadius: 8,
          height: 44
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {},
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          //padding: "0 9px 0 9px"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {}
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {},
        switchBase: (state) => ({
          '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: state.theme.palette.primary,
              opacity: 1
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3
            }
          }
        })
      }
    }
  }
});

export const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
