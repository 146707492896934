import cls from './ModalTitle.module.scss';

interface Props {
  icon: any;
  title?: string;
  text?: string;
}

export const ModalTitle = ({ icon, title, text }: Props) => {
  return (
    <div className={cls.container}>
      <img className={cls.icon} src={icon} alt="state-icon" />
      <div className={cls.text}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};
