import React, { ReactNode } from 'react';
import { Modal } from '@mui/material';
import cls from './CustomModal.module.scss';
import { ReactComponent as IconClose } from '../../../assets/icons/iconCloseModal.svg';

interface Props {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const CustomModal = ({ children, open, onClose }: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
      }}
    >
      <div className={cls.container}>
        <button onClick={onClose}>
          <IconClose />
        </button>
        {children}
      </div>
    </Modal>
  );
};
