import { redirect } from 'react-router-dom';
import { Paths } from '../providers/Router/paths';
import { useUserStore } from '../store';

export const requireProLoader = async () => {
  const proMode = useUserStore.getState().proMode;
  if (!proMode) {
    return redirect(Paths.APP);
  }
  return null;
};
