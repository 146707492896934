import { useEffect } from 'react';
import { useAppStore } from './store';
import { useWindowSize } from 'usehooks-ts';
import { Outlet } from 'react-router-dom';
import { LoaderLayout } from '../components/UI/LoaderLayout/LoaderLayout';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export function App() {
  const { isMobileWidth, setMobileWidth } = useAppStore();
  const { width } = useWindowSize();

  useEffect(() => {
    width > 768 ? setMobileWidth(false) : setMobileWidth(true);
  }, [width]);

  return (
    <div className="App">
      {!isMobileWidth && <Header />}
      <div className="app-content">
        <LoaderLayout>
          <Outlet />
        </LoaderLayout>
      </div>
      <Footer />
    </div>
  );
}
