import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface SignInState {
  phoneNumber: string;
  password: string;
  addPhone: (value: string) => void;
}

export const useSignInStore = create<SignInState>()(
  devtools(
    (set) => ({
      phoneNumber: '',
      password: '',
      addPhone: (value) => set({ phoneNumber: value })
    }),
    { name: 'signIn' }
  )
);
