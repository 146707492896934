import React from 'react';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { AccountMenu } from '../AccountMenu/AccountMenu';
import { ReactComponent as LogoSvg } from '../../assets/icons/logoPayout.svg';
import cls from './Header.module.scss';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/providers/Router/paths';
import { useUserStore } from '../../app/store';

export const Header = () => {
  const user = useUserStore((state) => state.user);

  return (
    <header className={cls.header}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <Link to={Paths.APP} className={cls.logo}>
            <LogoSvg />
          </Link>
          <div className={cls.nav}>
            <AccountMenu />
          </div>
        </div>
      </WidthLimiterPadding>
    </header>
  );
};
