import React, { useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';

interface Props {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
}

export const InputPassword = ({ label, ...props }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" style={{ gap: '8px' }}>
      <label htmlFor={label}>{label}</label>
      <TextField
        id={label}
        type={showPassword ? 'text' : 'password'}
        required
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prevState) => !prevState)}
                onMouseDown={handleMouseDownPassword}
                edge="start"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};
