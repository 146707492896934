import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/providers/Router/paths';
import { useOnClickOutside } from 'usehooks-ts';
import { CustomSwitcher } from '../UI/CustomSwitcher/CustomSwitcher';
import { ReactComponent as BurgerMenu } from '../../assets/icons/menu.svg';
import { ReactComponent as BurgerMenuClose } from '../../assets/icons/menu-close.svg';
import cls from './AccountMenu.module.scss';
import { useUserStore } from '../../app/store';
import { CustomModal } from '../UI/CustomModal';
import { ModalLogout } from '../ModalLogout';

export const AccountMenu = () => {
  const { proMode, switchProMode, user } = useUserStore();
  const [openMenu, setOpenMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const refMenu = useRef(null);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const logoutUser = () => {
    setShowModal(true);
    handleCloseMenu();
  };

  useOnClickOutside(refMenu, handleCloseMenu);

  return (
    <div className={cls.container}>
      <button
        className={cls.burgerBtn}
        onClick={() => setOpenMenu((prevState) => !prevState)}
      >
        {openMenu ? <BurgerMenuClose /> : <BurgerMenu />}
      </button>
      <nav
        ref={refMenu}
        className={cls.menu}
        style={openMenu ? { display: 'flex' } : {}}
      >
        <ul className={cls.list}>
          <li>
            <h4 className={cls.userPhone}>{user}</h4>
          </li>
          <li>
            <CustomSwitcher
              label={'PRO'}
              checked={proMode}
              setChecked={switchProMode}
            />
          </li>
          <li>
            <Link to={Paths.SETTINGS} onClick={handleCloseMenu}>
              Настройки
            </Link>
          </li>
          <li>
            <button onClick={logoutUser}>Выйти</button>
            <CustomModal open={showModal} onClose={() => setShowModal(false)}>
              <ModalLogout handleClose={() => setShowModal(false)} />
            </CustomModal>
          </li>
        </ul>
      </nav>
    </div>
  );
};
