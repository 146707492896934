import React, { useEffect, useState } from 'react';
import { FormTitle } from '../../components/UI/FormTitle';
import { Form } from '../../components/UI/Form';
import { Button } from '@mui/material';
import { CustomLink } from '../../components/UI/CustomLink';
import { Paths } from '../../app/providers/Router/paths';
import { InputPassword } from '../../components/UI/InputPassword';
import { useNavigate } from 'react-router-dom';
import cls from './SignUpPage.module.scss';
import { useUserStore, useSignUpStore } from '../../app/store';

export const Step3 = () => {
  const navigate = useNavigate();
  const phoneNumber = useSignUpStore((state) => state.phoneNumber);
  const addUser = useUserStore((state) => state.addUser);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const handleChangePass1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pass = e.target.value;
    pass.match(/^(?=.*\d)(?=.*[A-z])[0-9a-zA-Z]{8,}$/)
      ? setError1(false)
      : setError1(true);

    setPassword(pass);
  };

  const handleChangePass2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
  };

  useEffect(() => {
    password2 !== password ? setError2(true) : setError2(false);
  }, [password, password2]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (error1 || error2) {
      return;
    }

    addUser(phoneNumber);
    navigate(Paths.APP);
  };

  return (
    <>
      <FormTitle
        title={'Установите пароль'}
        text={'Для входа в систему придумайте пароль.'}
      />
      <Form onSubmit={handleSubmit}>
        <InputPassword
          value={password}
          onChange={handleChangePass1}
          label={'Пароль'}
          error={error1}
          helperText={
            error1
              ? 'Парол должен быть не менее 8 символов. Содержать латинские сиволы и цифры.'
              : ''
          }
        />
        <InputPassword
          value={password2}
          onChange={handleChangePass2}
          label={'Подтверждение пароля'}
          error={error2}
          helperText={error2 ? 'Пароли не совпадают' : ''}
        />
        <Button type="submit">Зарегистрироваться</Button>
        <div className={cls.forgot}>
          <div>или</div>
          <CustomLink href={Paths.SIGN_IN}>Войдите</CustomLink>
        </div>
      </Form>
    </>
  );
};
