import React, { useState } from 'react';
import { FormTitle } from '../../components/UI/FormTitle';
import { Form } from '../../components/UI/Form';
import cls from './SignUpPage.module.scss';
import { Button, TextField } from '@mui/material';
import { Paths } from '../../app/providers/Router/paths';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useSignUpStore } from '../../app/store';

function MyTimer({
  expiryTimestamp,
  onExpire
}: {
  expiryTimestamp: Date;
  onExpire: () => void;
}) {
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => onExpire()
  });

  return (
    <span style={{ textAlign: 'center' }}>
      <span>{minutes}</span>:<span>{seconds}</span>
    </span>
  );
}

export const Step2 = () => {
  const navigate = useNavigate();
  const phoneNumber = useSignUpStore((state) => state.phoneNumber);
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(true);
  const time: Date = new Date();
  time.setSeconds(time.getSeconds() + 20);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('submit');
    navigate(Paths.SIGN_UP_STEP_3);
  };

  return (
    <>
      <FormTitle
        title={'Введите код из SMS'}
        text={`Код отправлен на номер ${phoneNumber}`}
      />
      <Form onSubmit={handleSubmit}>
        <div className={cls.field}>
          <label htmlFor="code">Введите код</label>
          <TextField
            required
            id="code"
            name="code"
            placeholder="code"
            value={code}
            onChange={(e) =>
              e.target.value.length < 5 && setCode(e.target.value)
            }
          />
        </div>
        <Button type="submit" disabled={code.length < 4}>
          Продолжить
        </Button>
        <div className={cls.forgot}>
          {timer ? (
            <div>
              Отправить код повторно через:{' '}
              <MyTimer
                expiryTimestamp={time}
                onExpire={() => setTimer(false)}
              />
            </div>
          ) : (
            <Button onClick={() => setTimer(true)}>Выслать код повторно</Button>
          )}
        </div>
      </Form>
    </>
  );
};
