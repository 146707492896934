import React from 'react';
import cls from './FormTitle.module.scss';

interface Props {
  title: string;
  text: string;
}

export const FormTitle = ({ title, text }: Props) => {
  return (
    <div className={cls.FormTitle}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};
