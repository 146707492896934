import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../app/providers/Router/paths';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSignUpStore } from '../../app/store';
import { FormTitle } from '../../components/UI/FormTitle';
import { Form } from '../../components/UI/Form';
import { Button } from '@mui/material';
import { CustomLink } from '../../components/UI/CustomLink';
import { InputPhoneNum } from '../../components/UI/InputPhoneNum';
import cls from './SignUpPage.module.scss';

export const Step1 = () => {
  const navigate = useNavigate();
  const addPhone = useSignUpStore((state) => state.addPhone);
  const [phone, setPhone] = useState('+');
  const [error, setError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const phoneNumber = parsePhoneNumberFromString(phone);

    if (!phoneNumber?.isValid()) {
      setError(true);
      return;
    }

    console.log(phoneNumber?.number);
    console.log(phoneNumber?.country);

    addPhone(phone);
    navigate(Paths.SIGN_UP_STEP_2);
  };

  return (
    <>
      <FormTitle
        title={'Добро пожаловать'}
        text={'Войдите или зарегистрируйтесь'}
      />
      <Form onSubmit={handleSubmit}>
        <InputPhoneNum
          phone={phone}
          error={error}
          setPhone={setPhone}
          setError={setError}
        />
        <Button type="submit" disabled={error}>
          Продолжить
        </Button>
        <div className={cls.forgot}>
          <div>или</div>
          <CustomLink href={Paths.SIGN_IN}>Войдите</CustomLink>
        </div>
      </Form>
    </>
  );
};
